<template>
  <div class="pageContol listWrap templateList">
  <div class="framePage">
  <div class="framePage-title">
    <span>
      <em>当前位置：</em>
      <a href="javascript:;">培训管理</a>
      <i>></i>
      <a href="javascript:;" @click="$router.back()">课程列表</a>
      <i>></i>
      <a href="javascript:;" class="cur-a">关联课程</a>
    </span>
  </div>
  <div class="framePage-body">
  <div class="flexdc flex1" style="padding: 30px 20px;">
    <div>
      <div style="display:flex; height:100%">
        <div style="flex:1; width:60%">
          <div class="operationControl">
            <div class="searchbox" style="padding-left:0">
              <div title="课程名称" class="searchboxItem ci-full">
                <span class="itemLabel">课程名称:</span>
                <el-input
                  v-model="coursrName"
                  type="text"
                  size="small"
                  placeholder="请输入课程名称"
                  clearable
                />
              </div>
              <span title="课程来源" class="searchboxItem ci-full flexcc">
                <span class="itemLabel" >课程来源:</span>
                <el-select v-model="courseOrigin" clearable size="small">
                  <el-option
                    v-for="item in courseOriginList"
                   :label="item.label" 
                   :key="item.value"
                   :value="item.value">
                  </el-option>
                </el-select>
              </span>
              <div>
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询
                </el-button>
              </div>
            </div>
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader4"
            @row-click="clickRow"
          >
            <el-table-column
              label="课程名称"
              align="left"
              prop="courseName"
              fixed
              width="250"
            />
            <el-table-column
              label="课件数量"
              align="center"
              show-overflow-tooltip
              prop="coursewareNum"
            />
            <el-table-column
              label="课程总时长"
              align="center"
              show-overflow-tooltip
              width="180"
              prop="totalDuration"
            >
            <template slot-scope="scoped">
              {{getTime(scoped.row.totalDuration)}}
            </template>
            </el-table-column>
            <el-table-column
              label="课程来源"
              align="center"
              show-overflow-tooltip
              prop="courseOrigin"
            >
            <template slot-scope="scoped">
              {{$setDictionary('COURSEORGIN',scoped.row.courseOrigin)}}
            </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="100"
              fixed="right"
            >
            <template slot-scope="scoped">
              <el-button type="text" size="small">选择</el-button>
            </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
        <div style="display:flex; flex-direction: column;flex:1;width:40%">
          <div style="flex:1;display: flex;" class="ovy-a">
            <div v-if="!rowId" class="flex1 flexdcc">
              <img
                src="@/assets/recomEmpty.png"
                alt
                class
                style="width: 12rem;"
              />
              <span>请在左侧选择您想要配置的课程</span>
            </div>
            <div v-else style="height: 10px;">
              <h3 style="margin-bottom: 15px;">课程信息</h3>
              <p style="margin-bottom: 15px;">
                <span>视频累计时长：{{ getTime(duration) }}</span>
              </p>
              <h3 style="padding-bottom:15px;">
                章节编排
                <!-- <el-button @click="setCheckedNodes" class="bgc-bv" size="mini">全选</el-button> -->
              </h3>
              <el-button class="bgc-bv" @click="selectChange" size="mini"
                >反选</el-button
              >
              <div style="display: flex;">
                <el-tree
                  ref="tree"
                  :data="data"
                  show-checkbox
                  node-key="courseKpointId"
                  default-expand-all
                  :default-checked-keys="this.selects"
                  :expand-on-click-node="false"
                  :props="defaultProps"
                  @check-change="getChecked"
                >
                  <span class="custom-tree-node" slot-scope="{ data }">
                    <template v-if="data.level == 1">
                      <p class="ellipsis" :title="data.catalogName">
                        {{ data.catalogName }}
                        <span>{{ data.kpointDurationStr }}</span>
                      </p>
                    </template>
                    <template v-else-if="data.level == 2">
                      <span
                        v-if="data.list.length > 0"
                        style="display: flex;flex-direction: row;align-items: center;"
                      >
                        <i class="courseIcon" v-if="data.list[0].kpointId"></i>
                        <i
                          class="paperIcon"
                          v-else-if="data.list[0].paperId"
                        ></i>
                      </span>
                      <span
                        v-text="data.catalogName"
                        class="ellipsis"
                        :title="data.catalogName"
                      >
                      </span>
                      <span>{{ data.kpointDurationStr }}</span>
                      <el-button
                        type="text"
                        @click="showVideo(data)"
                        size="mini"
                        style="margin:0 1rem"
                        >预览</el-button
                      >
                    </template>
                    <template v-else>
                      <span
                        v-text="data.catalogName"
                        :title="data.catalogName"
                        class="ellipsiss"
                      ></span>
                      <el-button
                        type="text"
                        @click="showPaper(data)"
                        size="mini"
                        style="margin:0 1rem"
                        >预览</el-button
                      >
                    </template>
                  </span>
                </el-tree>
              </div>
            </div>
          </div>
          <div
            style="display: flex; justify-content: center;padding-top:17px"
            v-show="rowId"
          >
            <el-button @click="$router.back()" class="bgc-bv">取消</el-button>
            <el-button @click="getCheckedNodes" class="bgc-bv">确定</el-button>
          </div>
        </div>
      </div>
      </div>
              </div>
    </div>
    <PaperShow ref="PaperShow" />
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-if="videoType"
      />
      <player_1
        :videoId="videoId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-else
      />
    </el-dialog>
  </div>
  </div>
</template>
<script>
let id = 1000;
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import PaperShow from "@/views/resourse/popup/PaperShow";
import List from "@/mixins/List";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
export default {
  components: {
    Empty,
    PageNum,
    PaperShow,
    player_1,
    player_2,
  },
  mixins: [List],
  doNotInit:true,
  data() {
    return {
      projectId:'',
      rowId: "",
      data: [],
      radio: "",
      coursrName: "",
      courseOrigin: "",
      courseOriginList:[],
      defaultProps: {
        id: "courseKpointId",
        children: "children",
        label: "courseKpointId",
      },
      datatreeList: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      selects: [],
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      chapterSchema: true,
      stu: false,
      buyId: "",
      notifyBody: {},
      videoId: "", // 視頻id
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      duration: 0, //视频总时长
    };
  },
  watch: {
  },
  created() {
    this.getcourseOriginList()
    this.projectId = this.$route.query.projectId
    if(this.projectId){
      this.getData()
    }
  },
  mounted() {
    //  this.$nextTick(() => {
    //     this.$refs.tree.setCheckedNodes(this.data);
    //   });
  },
  methods: {
    //获取课程来源list
    getcourseOriginList() {
      const courseOriginList = this.$setDictionary("COURSEORGIN", "list");
      const list = [];
      for (const key in courseOriginList) {
        list.push({
          value: key,
          label: courseOriginList[key],
        });
      }
      this.courseOriginList = list;
      console.log(this.courseOriginList);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: 10,
        pageSize: this.pageSize,
        courseName: this.coursrName || "",
        courseOrigin: this.courseOrigin || "",
        projectId: this.projectId,
        coursePublish:'20'
      };
      this.doFetch({
        url: "/biz/et/course/page",
        params,
        pageNum,
      });
    },
    clickRow(row) {
      this.rowId = row.courseId;
      this.getDetail(row.courseId);
      this.$nextTick(() => {
        this.$refs.tree.setCheckedNodes(this.data);
      });
    },
    getChecked() {
      let duration = 0;
      //勾选的
      this.$refs.tree.getCheckedNodes().map((item, index) => {
        console.log(item);
        if (item.level == 2) {
          if (item.kpointId) {
            duration += item.kpointDuration;
          }
        }
      });
      this.duration = duration;
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    /**
     * 保存
     */
    getCheckedNodes() {
      let checked = [];
      checked = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
      if (checked.length == 0) {
        this.$message({
          message: "请选择课程编排",
          type: "warning",
        });
        return false;
      }
      let parmar = {
        courseId: this.rowId,
        modify: 1,
        projectId: this.projectId,
        selectIds: checked,
        selectNode: this.data,
        buyId: this.buyId,
      };

      this.$post(
        "/biz/et/trainTask/course/configureProjectCourse",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$notify.close();
            this.$router.back();
          }
        })
        .catch((err) => {
          return;
        });
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      distorySwigger();
    },
    // 预览
    showVideo(data) {
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.list[0].kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.list[0].kpointVideoId;
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.label;
            _this.kpointSource = data.list[0].kpointSource;
            _this.dialogVisible1 = true;
          });
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        this.showPaper(data);
      }
    },
    /**
     *获取 树结构
     */
    getDetail(rowId) {
      this.$post("/biz/et/trainTask/course/etTrainTaskUsableCourseOutline", {
        courseId: rowId,
      }).then((result) => {
        if (result.status === "0") {
          console.log(result);
          this.creatData(result.data.tree);
          this.selects = result.data.allKey.map(Number);
          this.buyId = result.data.tree.buyId;
          this.duration = result.data.tree.duration;
        }
      });
    },
    creatData(data = {}) {
      let list = [];
      this.chapterSchema = data.chapterSchema;
      if (data.chapterSchema) {
        // 样式一 ------> 三层
        data.nodes.forEach((element) => {
          let nodeList = [];
          list.push({
            courseKpointId: element.courseKpointId,
            catalogName: element.catalogName,
            kpointDuration: element.kpointDuration,
            kpointDurationStr: element.kpointDurationStr,
            parentNodeId: element.parentNodeId,
            children: nodeList,
            level: 1,
            requiredState: false,
          });
          (element.child || []).forEach((obj) => {
            nodeList.push(this.created2dom(obj));
          });
        });
      } else {
        data.nodes.forEach((element) => {
          list.push(this.created2dom(element));
        });
      }
      this.data = list;
    },
    created2dom(obj) {
      let children = [];
      if (obj.kpointId && obj.paperId) {
        children = [
          {
            catalogName: obj.paperName,
            courseKpointId: obj.courseKpointId,
            sourceCourseKpointId: obj.courseKpointId,
            level: 3,
            paperId: obj.paperId,
          },
        ];
      }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointId: obj.kpointId,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          kpointVideoId: obj.kpointVideoId,
          buyId: obj.buyId,
          kpointSource: obj.kpointSource,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          paperId: obj.paperId,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          catalogName: obj.catalogName,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: children,
          requiredState: obj.requiredState,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          buyId: obj.buyId,
        };
      } else {
        return {
          catalogName: obj.catalogName,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: [],
          requiredState: obj.requiredState,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          buyId: obj.buyId,
          paperId: obj.paperId,
          paperName: obj.paperName,
        };
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    //反选
    selectChange() {
      let res = this.$refs.tree;
      let nodes = res.getCheckedNodes(true, true);
      this.batchSelect(this.data, res, true, nodes);
    },
    batchSelect(nodes, refs, flag, selecteds) {
      if (typeof nodes != "undefined") {
        nodes.forEach((element) => {
          refs.setChecked(element, flag, true);
        });
      }
      if (typeof selecteds != "undefined") {
        selecteds.forEach((node) => {
          refs.setChecked(node, !flag, true);
        });
      }
    },
  },
};
</script>
<style lang="less">
.el-input-number__decrease {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
<style lang="less" scoped>
.el-tree {
  width: 100%;
}

.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}

.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tree-node {
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0px;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 25rem;
  font-size: 14px;
}

.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 23.85rem;
  font-size: 14px;
}

.el-table .warning-row {
  background: red;
}

.el-table .success-row {
  background: #ffffff;
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}


//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF;
}
</style>
